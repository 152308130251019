import { faSignal, faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@torqit/torq-tools-react';
import { Tooltip } from 'antd';
import React from 'react';

export interface SensorTypeToolTipProps {
  tSensActive?: boolean;
}

export const SensorTypeToolTip: React.FC<SensorTypeToolTipProps> = ({
  tSensActive,
}) => {
  return (
    <Tooltip
      title={<div>{tSensActive ? 'TSenS' : 'SenS'} Sensor</div>}
      placement="right"
    >
      <div>
        <Box padding={{ horizontal: 'sm' }}>
          <FontAwesomeIcon icon={tSensActive ? faTemperatureHigh : faSignal} />
        </Box>
      </div>
    </Tooltip>
  );
};
