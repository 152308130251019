import React, { useState } from 'react';
import {
  useLocalStorage,
  Card,
  Stack,
  Select,
  Box,
  Radio,
  Switch,
  Tooltip,
  Button,
} from '@torqit/torq-tools-react';
import { SensorReadingsGraph } from 'SensorReadings';
import { SentryApiClient } from '_generated/api';
import { SensorRangeComparisonDrawer } from './SensorRangeComparisonDrawer';
import { SensorRange } from 'SensorReadings/interfaces';
import { SensorReadingsRangeData } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { fullWidthStyles, positionsStyles, useBreakpoints } from 'App';
import { ChartLegend } from './ChartLegend';
import classNames from 'classnames';
import { ChartConfigurer } from './ChartConfigurer/ChartConfigurer';
import { DateServiceSingleton } from 'App/utils/DateService';
import { PresetButtons } from './PresetButtons';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DotChartOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import styles from './SensorReadingsCard.module.css';
import { DateSlider } from './DateSlider';

interface Range {
  min?: number;
  max?: number;
}

const SELECTED_CHART_STORAGE_KEY = 'sentry_selected_chart';
const COLLAPSED_STORAGE_KEY = 'sentry_collapsed_controls';

export interface SensorChartsCardProps {
  title?: React.ReactNode;
  targetRange: SensorRange;
  comparisons?: SensorRange[];
  timezone?: string;
  comparableSensors?: SentryApiClient.SensorDTO[];
  readings?: SensorReadingsRangeData[];
  startDate: Date;
  endDate: Date;
  enableImport?: boolean;
  onComparison?: (primary: SensorRange, comparisons: SensorRange[]) => void;
  onTimezoneChange?: (timezone: string) => void;
  onFlipAxis?: (rangeId: string) => void;
}

export enum ChartTypes {
  line,
  plot,
}

const SHOW_EVENT_LOGS_ON_CHART = 'show_event_logs_on_chart';
const SHOW_EVENT_TSENS_CHART = 'show_tsens_chart_';

export const SensorReadingsCard: React.FC<SensorChartsCardProps> = ({
  title,
  targetRange,
  comparisons,
  timezone,
  comparableSensors,
  readings,
  startDate,
  endDate,
  enableImport,
  onComparison,
  onTimezoneChange,
  onFlipAxis,
}) => {
  const { sm, md, lg, xxl } = useBreakpoints();

  const [presetClicked, setPresetClicked] = useState<number | undefined>(
    7 * 24
  );

  const [showComparisonDrawer, setShowComparisonDrawer] = useState(false);
  const [leftRange, setleftRange] = useState<Range>({});
  const [rightRange, setRightRange] = useState<Range>({});

  //useLocalStorage() only works with objects hence the weird object
  const [isCollapsed, setCollapsed] = useLocalStorage(COLLAPSED_STORAGE_KEY, {
    collapsed: false,
  });

  const [showEventLogs, setShowEventLogs] = useLocalStorage(
    SHOW_EVENT_LOGS_ON_CHART,
    true
  );

  const [showTSensChart, setShowTSensChart] = useLocalStorage(
    SHOW_EVENT_TSENS_CHART + targetRange.sensor.id,
    false
  );

  const [selectedChart, setSelectedChart] = useLocalStorage<{
    chart: ChartTypes;
  }>(SELECTED_CHART_STORAGE_KEY, { chart: ChartTypes.line });

  const timezones = DateServiceSingleton.GetTimezones();

  const applyPresetTime = (hours: number) => {
    if (timezone == null) {
      return;
    }

    const now = new Date();

    setPresetClicked(hours);
    onComparison &&
      onComparison(
        {
          sensor: targetRange.sensor,
          from: DateServiceSingleton.ConvertToTimezoneTime(
            new Date(now.getTime() - 1000 * 60 * 60 * hours),
            timezone
          ),
          to: DateServiceSingleton.ConvertToTimezoneTime(now, timezone),
        },
        []
      );
  };

  const mobileStyling = {
    margin: {
      marginBottom: sm ? '0' : 'var(--torq-experimental-rhythm-sm)',
    },
    overflow: {
      overflow: sm ? undefined : 'auto',
    },
  };

  return (
    <>
      <Card
        id={styles.chartWrapper}
        title={
          <Stack
            alignment={md ? 'middle' : 'start'}
            gap="apart"
            direction={sm ? 'horizontal' : 'vertical'}
          >
            <Stack alignment="middle" style={mobileStyling.margin}>
              {title}
            </Stack>
            <Stack gap={10} alignment="middle">
              Timezone:
              <Select
                className={classNames(
                  fullWidthStyles.fullWidth,
                  styles.timezoneSelect
                )}
                onChange={onTimezoneChange}
                value={timezone}
                showSearch
              >
                {timezones?.map((s) => (
                  <Select.Option key={s} value={s}>
                    {s}
                  </Select.Option>
                ))}
              </Select>
            </Stack>
          </Stack>
        }
      >
        <div>
          <Box padding={{ bottom: 'md' }}>
            <Stack
              gap="lg"
              alignment={lg ? 'middle' : 'start'}
              direction={lg ? 'horizontal' : 'vertical'}
            >
              <Tooltip
                placement={'top'}
                mouseEnterDelay={0.5}
                title={'Toggle between a scatter plot and a line plot'}
              >
                <Switch
                  checkedChildren={<LineChartOutlined />}
                  unCheckedChildren={<DotChartOutlined />}
                  defaultChecked
                  checked={selectedChart.chart == ChartTypes.line}
                  onChange={(c) => {
                    var chartType = {
                      chart: c ? ChartTypes.line : ChartTypes.plot,
                    };
                    setSelectedChart(chartType);
                  }}
                />
              </Tooltip>
              <Stack.Item>
                {targetRange.sensor.id && (
                  <DateSlider
                    sensorId={targetRange.sensor.id}
                    start={targetRange.from}
                    end={targetRange.to}
                    onChange={(s, e) =>
                      onComparison &&
                      onComparison(
                        { sensor: targetRange.sensor, from: s, to: e },
                        []
                      )
                    }
                  />
                )}
              </Stack.Item>
              <PresetButtons
                lastPresetClicked={presetClicked}
                onClick={applyPresetTime}
              />
            </Stack>
          </Box>
        </div>

        <Stack stretch direction={xxl ? 'horizontal' : 'vertical'} gap="md">
          <div
            className={classNames(positionsStyles.relative, styles.chart, {
              [styles.compressed]: xxl && !isCollapsed.collapsed,
            })}
          >
            <Stack direction={'vertical'}>
              <Stack.Item className={styles.scrollable}>
                <ChartLegend
                  ranges={readings}
                  timezone={timezone}
                  onFlipAxis={onFlipAxis}
                  showDetails={false}
                  sensor={targetRange.sensor}
                />
              </Stack.Item>
              <SensorReadingsGraph
                targetRange={targetRange}
                type={selectedChart.chart}
                yMin={leftRange.min}
                yMax={leftRange.max}
                rightYMin={rightRange.min}
                rightYMax={rightRange.max}
                startDate={startDate}
                endDate={endDate}
                readings={readings}
                bands={
                  readings?.every((r) => r.sensor.id === targetRange.sensor.id)
                    ? showTSensChart
                      ? {
                          redHigh: targetRange.sensor.tRedHighBand,
                          amberHigh: targetRange.sensor.tAmberHighBand,
                          amberLow: targetRange.sensor.tAmberLowBand,
                          redLow: targetRange.sensor.tRedLowBand,
                        }
                      : {
                          redHigh: targetRange.sensor.redHighBand,
                          amberHigh: targetRange.sensor.amberHighBand,
                          amberLow: targetRange.sensor.amberLowBand,
                          redLow: targetRange.sensor.redLowBand,
                        }
                    : // When comparing different sensors, we hide the alarm bands entirely since they likely aren't the same for each sensor
                      undefined
                }
                yLabel={
                  showTSensChart
                    ? 'TSenS'
                    : targetRange.sensor.readingsLabel != null
                    ? targetRange.sensor.readingsLabel
                    : 'SenS'
                }
                yUnit={
                  targetRange.sensor.readingsUnit != null
                    ? targetRange.sensor.readingsUnit
                    : undefined
                }
                timezone={timezone}
                showEventLogs={showEventLogs}
                showTSensChart={showTSensChart}
              />
            </Stack>
          </div>
          {xxl && (
            <Button
              className={styles.fullHeight}
              size="small"
              onClick={() =>
                setCollapsed({ collapsed: !isCollapsed.collapsed })
              }
            >
              {isCollapsed.collapsed ? (
                <ArrowLeftOutlined />
              ) : (
                <ArrowRightOutlined />
              )}
            </Button>
          )}

          <div
            className={classNames(
              lg ? styles.configurerWrapper : undefined,
              styles.fullHeight,
              {
                [styles.collapsed]: xxl && isCollapsed.collapsed,
              }
            )}
          >
            <Stack gap="sm">
              <div className={styles.configurer}>
                <Stack direction="vertical" stretch fill gap="sm">
                  <div className={styles.bottomBorder}>
                    <Box padding={{ bottom: 'sm' }}>
                      <ChartConfigurer
                        range={targetRange}
                        comparisons={comparisons ?? []}
                        minY={leftRange.min}
                        maxY={leftRange.max}
                        enableImport={enableImport}
                        timezone={timezone}
                        showEventLogs={showEventLogs}
                        showTSensChart={showTSensChart}
                        hasRightAxis={readings?.some((r) => r.isRightAxis)}
                        onYAxisChange={(min, max) => setleftRange({ min, max })}
                        onRightYAxisChange={(min, max) =>
                          setRightRange({ min, max })
                        }
                        onComparison={(primary, updatedComparisons) => {
                          setPresetClicked(undefined);
                          onComparison &&
                            onComparison(primary, updatedComparisons);
                        }}
                        onCompareButtonClicked={() =>
                          setShowComparisonDrawer(true)
                        }
                        onShowEventLogs={setShowEventLogs}
                        onShowTSensChart={setShowTSensChart}
                      />
                    </Box>
                  </div>

                  <Stack.Item className={styles.scrollable}>
                    <ChartLegend
                      ranges={readings}
                      timezone={timezone}
                      onFlipAxis={onFlipAxis}
                      sensor={targetRange.sensor}
                    />
                  </Stack.Item>
                </Stack>
              </div>
            </Stack>
          </div>
        </Stack>
      </Card>
      <SensorRangeComparisonDrawer
        visible={showComparisonDrawer}
        targetSensorRange={targetRange}
        comparisons={comparisons}
        comparableSensors={comparableSensors}
        onSubmit={(primary, comparisons) => {
          onComparison && onComparison(primary, comparisons);
        }}
        onClose={() => setShowComparisonDrawer(false)}
      />
    </>
  );
};
