import { getNiceColor } from 'App/utils/niceColors';
import moment from 'moment';
import { SensorReadingsRangeData } from 'SensorReadings/hooks/useSensorReadings';
import { SensorRange } from 'SensorReadings/interfaces';
import { ChartDataProps } from './Charts/LineChart/LineChart';
import { getTimezoneAdjustedTime } from './SensorReadingsGraph';
import { SentryApiClient } from '_generated/api';

//Say we're comparing two ranges; left axis is 0-300 and right axis is
// 350-400. Because the right axis is purely cosmetic, the displayed range
// only respects data on the left axis which means our 0-300 range is not
// going to display the other data. To actually display our right axis
// data, we need to transform it so that it actually fits on the left axis
export function formatRightAxisData(
  value: number,
  leftYMin: number | undefined,
  leftYMax: number,
  rightYMin: number,
  rightYMax: number
) {
  const effectiveMin = leftYMin ?? 0;
  const ratio = (leftYMax - effectiveMin) / (rightYMax - rightYMin);

  return (value - rightYMin) * ratio + effectiveMin;
}

export enum DataDisplayed {
  Readings,
  TSens,
}

export function getChartData(
  relativeTimezoneOffset: number,
  timezone: string | undefined,
  timezoneOffset: number,
  targetRange: SensorRange,
  yMin: number | undefined,
  yMax: number | undefined,
  autoMaxY: number,
  rightYMin: number | undefined,
  rightYMax: number | undefined,
  bounds: { maxLeftAxis: number | undefined; maxRightAxis: number },
  readings?: SensorReadingsRangeData[],
  showTSensChart?: boolean
) {
  const data: ChartDataProps[] = [];

  readings?.forEach((r, index) => {
    if (r.data == null) {
      return;
    }

    if (showTSensChart) {
      const tSensData: ChartDataProps = {
        id:
          r.sensor.sensorName +
          ' - ' +
          new Date(
            r.startDate.getTime() - relativeTimezoneOffset * 1000 * 60
          ).toLocaleString(),
        color: getNiceColor(index),
        isPrimarySensor: r.sensor.id === targetRange.sensor.id,
        data: r.data.map((s) => {
          return {
            rangeId: r.id,
            x: moment(
              getTimezoneAdjustedTime(
                s.roundedDate,
                relativeTimezoneOffset - timezoneOffset
              )
            ).toDate(),
            y:
              s.tSens !== null && s.tSens !== undefined
                ? r.isRightAxis
                  ? formatRightAxisData(
                      s.tSens,
                      yMin,
                      yMax ?? autoMaxY,
                      rightYMin ?? 0,
                      rightYMax ?? bounds.maxRightAxis
                    )
                  : s.tSens
                : null,
            //We want our data points to align perfectly on the grid, but we
            // don't want to forget what time they actually happened at. To
            // keep track, we tack on an extra field here that the tooltip
            // then makes use of.
            actualDate: moment(
              getTimezoneAdjustedTime(s.readingTime, relativeTimezoneOffset)
            ).toDate(),
            actualValue: s.rawValue,
            temperature: s.temperature,
            tSens: s.tSens,
          };
        }),
        dateOffset: r.offset,
        events: r.events,
      };

      data.push(tSensData);
    } else {
      const chartData: ChartDataProps = {
        id:
          r.sensor.sensorName +
          ' - ' +
          new Date(
            r.startDate.getTime() - relativeTimezoneOffset * 1000 * 60
          ).toLocaleString(),
        color: getNiceColor(index),
        isPrimarySensor: r.sensor.id === targetRange.sensor.id,
        data: r.data.map((s) => {
          return {
            rangeId: r.id,
            x: moment(
              getTimezoneAdjustedTime(
                s.roundedDate,
                relativeTimezoneOffset - timezoneOffset
              )
            ).toDate(),
            y:
              s.rawValue !== null && s.rawValue !== undefined
                ? r.isRightAxis
                  ? formatRightAxisData(
                      s.rawValue,
                      yMin,
                      yMax ?? autoMaxY,
                      rightYMin ?? 0,
                      rightYMax ?? bounds.maxRightAxis
                    )
                  : s.rawValue
                : null,
            //We want our data points to align perfectly on the grid, but we
            // don't want to forget what time they actually happened at. To
            // keep track, we tack on an extra field here that the tooltip
            // then makes use of.
            actualDate: moment(
              getTimezoneAdjustedTime(s.readingTime, relativeTimezoneOffset)
            ).toDate(),
            actualValue: s.rawValue,
            temperature: s.temperature,
            tSens: s.tSens,
          };
        }),
        dateOffset: r.offset,
        events: r.events,
      };

      data.push(chartData);
    }
  });

  return data;
}

export type Bounds = {
  maxLeftAxis: number | undefined;
  maxRightAxis: number;
};

export function getChartBounds(
  readings: SensorReadingsRangeData[] | undefined,
  showTSensChart: boolean
) {
  if (showTSensChart) {
    return {
      maxLeftAxis: readings
        ?.filter((r) => !r.isRightAxis)
        .reduce(
          (maxReadingValue, currentRange) =>
            currentRange?.maxTSensReading?.tSens &&
            currentRange.maxTSensReading.tSens > maxReadingValue
              ? currentRange.maxTSensReading.tSens
              : maxReadingValue,
          0
        ),
      maxRightAxis:
        (readings
          ?.filter((r) => r.isRightAxis)
          .reduce(
            (maxReadingValue, currentRange) =>
              currentRange.maxReading?.tSens &&
              currentRange.maxReading.tSens > maxReadingValue
                ? currentRange.maxReading.tSens
                : maxReadingValue,
            0
          ) ?? 1000) * 1.1,
    };
  } else {
    return {
      maxLeftAxis: readings
        ?.filter((r) => !r.isRightAxis)
        .reduce(
          (maxReadingValue, currentRange) =>
            currentRange.maxReading?.rawValue &&
            currentRange.maxReading.rawValue > maxReadingValue
              ? currentRange.maxReading.rawValue
              : maxReadingValue,
          0
        ),
      maxRightAxis:
        (readings
          ?.filter((r) => r.isRightAxis)
          .reduce(
            (maxReadingValue, currentRange) =>
              currentRange.maxReading?.rawValue &&
              currentRange.maxReading.rawValue > maxReadingValue
                ? currentRange.maxReading.rawValue
                : maxReadingValue,
            0
          ) ?? 1000) * 1.1,
    };
  }
}
