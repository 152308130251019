import {
  CaretLeftFilled,
  CaretRightFilled,
  CloseCircleFilled,
  FundViewOutlined,
} from '@ant-design/icons';
import { Box, Button, Stack, Tooltip } from '@torqit/torq-tools-react';
import React from 'react';
import { SensorRange } from 'SensorReadings/interfaces';
import { DatePicker, Switch } from 'antd';
import moment from 'moment';
import { ImportExportButtons } from './ImportExportButtons';
import { YAxisOptions } from './YAxisOptions';
import { useBreakpoints } from 'App';
import styles from './ChartConfigurer.module.css';

export interface ChartConfigurerProps {
  range: SensorRange;
  comparisons: SensorRange[];
  minY?: number;
  maxY?: number;
  rightYMin?: number;
  rightYMax?: number;
  enableImport?: boolean;
  timezone?: string;
  showEventLogs?: boolean;
  showTSensChart?: boolean;
  hasRightAxis?: boolean;
  onYAxisChange?: (min?: number, max?: number) => void;
  onRightYAxisChange?: (min?: number, max?: number) => void;
  onComparison?: (primary: SensorRange, comparisons: SensorRange[]) => void;
  onCompareButtonClicked?: () => void;
  onShowEventLogs?: (show: boolean) => void;
  onShowTSensChart?: (show: boolean) => void;
}

export const ChartConfigurer: React.FC<ChartConfigurerProps> = ({
  range,
  comparisons,
  minY,
  maxY,
  rightYMin,
  rightYMax,
  enableImport,
  timezone,
  showEventLogs,
  showTSensChart,
  hasRightAxis,
  onYAxisChange,
  onRightYAxisChange,
  onComparison,
  onCompareButtonClicked,
  onShowEventLogs,
  onShowTSensChart,
}) => {
  const updateRanges = (start: Date, end: Date) => {
    const primary: SensorRange = { sensor: range.sensor, from: start, to: end };
    const diff = end.getTime() - start.getTime();

    const updatedComparisons: SensorRange[] = comparisons.map((c) => ({
      ...c,
      to: new Date(c.from.getTime() + diff),
    }));

    onComparison && onComparison(primary, updatedComparisons);
  };

  const getDateRangeDuration = () => range.to.getTime() - range.from.getTime();

  const { sm, lg } = useBreakpoints();

  const mobileStyling = {
    mobileFlexDirection: {
      flexDirection: sm ? undefined : 'column',
    },
    bottomMargin: {
      marginBottom: sm ? undefined : 'var(--torq-experimental-rhythm-lg)',
    },
  };

  return (
    <Stack direction="vertical" stretch gap="lg">
      <Stack direction="vertical" gap="xs">
        <Stack gap="xs">
          <Tooltip
            title={
              <div>
                View{' '}
                <i>
                  {moment(range.from)
                    .subtract(getDateRangeDuration())
                    .format('MMM D, LT')}
                </i>{' '}
                to <i>{moment(range.from).format('MMM D, LT')}</i>
              </div>
            }
          >
            <Button
              icon={<CaretLeftFilled />}
              onClick={() =>
                updateRanges(
                  new Date(range.from.getTime() - getDateRangeDuration()),
                  range.from
                )
              }
            ></Button>
          </Tooltip>

          <DatePicker.RangePicker
            className={lg ? undefined : styles.datePicker}
            showTime
            format="YYYY-MM-DD HH:mm"
            value={[moment(range.to), moment(range.from)]}
            onChange={(r) =>
              updateRanges &&
              r &&
              r[0] &&
              r[1] &&
              updateRanges(r[0].toDate(), r[1].toDate())
            }
          />
          <Tooltip
            title={
              <div>
                View <i>{moment(range.to).format('MMM D, LT')}</i> to{' '}
                <i>
                  {moment(range.to)
                    .add(getDateRangeDuration())
                    .format('MMM D, LT')}
                </i>
              </div>
            }
            placement="topLeft"
          >
            <Button
              icon={<CaretRightFilled />}
              onClick={() =>
                updateRanges(
                  range.to,
                  new Date(range.to.getTime() + getDateRangeDuration())
                )
              }
            ></Button>
          </Tooltip>
        </Stack>

        <Stack>
          <Button
            type="primary"
            icon={<FundViewOutlined />}
            onClick={onCompareButtonClicked}
            disabled={timezone == null}
          >
            Add Comparisons
          </Button>
          {comparisons.length !== 0 && (
            <Box padding={{ left: 'xs' }}>
              <Tooltip overlay="Clear Comparisons">
                <Button
                  type="primary"
                  danger
                  icon={<CloseCircleFilled />}
                  onClick={() => onComparison && onComparison(range, [])}
                />
              </Tooltip>
            </Box>
          )}
        </Stack>
      </Stack>

      <div>
        <strong>Y Axis:</strong>
        <Stack
          gap={lg ? 'gutter' : 'lg'}
          direction={lg ? 'horizontal' : 'vertical'}
        >
          <YAxisOptions
            label="Left Axis"
            minY={minY}
            maxY={maxY}
            onChange={onYAxisChange}
          />
          <YAxisOptions
            label="Right Axis"
            minY={rightYMin}
            maxY={rightYMax}
            onChange={onRightYAxisChange}
            enabled={hasRightAxis}
          />
        </Stack>
      </div>
      <Stack
        gap={lg ? 'apart' : 'lg'}
        direction={sm ? 'horizontal' : 'vertical'}
      >
        <ImportExportButtons
          range={range}
          enableImport={enableImport}
          timezone={timezone}
        />
        <Stack
          direction="horizontal"
          alignment="end"
          gap="md"
          style={mobileStyling.bottomMargin}
        >
          <Stack direction="vertical">
            <strong>{showTSensChart ? 'TSenS:' : 'SenS:'}</strong>
            <Switch checked={showTSensChart} onChange={onShowTSensChart} />
          </Stack>
          <Stack direction="vertical">
            <strong>Event Logs:</strong>
            <Switch checked={showEventLogs} onChange={onShowEventLogs} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
