import moment from 'moment';
import { LineChart, SensorReadingBands } from './Charts/LineChart';
import { ScatterPlotChart } from './Charts/SensorScatterPlotChart';
import { useMemo } from 'react';
import { SensorReadingsRangeData } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { DateServiceSingleton } from 'App/utils/DateService';
import { ChartTypes } from 'Sensors';
import { useChartClicker } from './ChartClickerProvider';
import { calculateClickedPoint } from './calculateClickedPoint';
import { SensorRange } from 'SensorReadings/interfaces';
import { getChartBounds, getChartData } from './ChartHelper';

export function getTimezoneAdjustedTime(
  date: Date | undefined,
  offset: number
) {
  return (date?.getTime() ?? 0) - offset * 1000 * 60;
}

export interface SensorReadingsGraphProps {
  type: ChartTypes;
  targetRange: SensorRange;
  startDate: Date;
  endDate: Date;
  readings?: SensorReadingsRangeData[];
  yMin?: number;
  yMax?: number;
  rightYMin?: number;
  rightYMax?: number;
  bands?: SensorReadingBands;
  yLabel?: string;
  yUnit?: string;
  timezone?: string;
  showEventLogs?: boolean;
  showTSensChart?: boolean;
}

export const SensorReadingsGraph: React.FC<SensorReadingsGraphProps> = ({
  type = ChartTypes.line,
  readings,
  yMin,
  yMax,
  rightYMin,
  rightYMax,
  startDate,
  endDate,
  bands,
  yLabel = 'Carbon Consumption Rate',
  yUnit,
  timezone,
  showEventLogs,
  showTSensChart,
  targetRange,
}) => {
  const Chart = type === ChartTypes.line ? LineChart : ScatterPlotChart;

  const timezoneOffset = DateServiceSingleton.GetMyTimezoneOffset();
  const relativeTimezoneOffset = useMemo(
    () =>
      timezone ? DateServiceSingleton.GetRelativeTimezoneOffset(timezone) : 0,
    [timezone]
  );

  const { onClick } = useChartClicker();

  const bounds = useMemo(() => {
    return getChartBounds(readings, showTSensChart ?? false);
  }, [readings, showTSensChart, targetRange.sensor.tSenSActive]);

  const autoMaxY = useMemo(
    () =>
      bounds.maxLeftAxis
        ? bounds.maxLeftAxis
        : Math.max(
            bands?.redHigh ?? 0,
            bands?.amberHigh ?? 0,
            bounds.maxLeftAxis ?? 0
          ) * 1.1,
    [bands, bands?.redHigh, bands?.amberHigh, bounds]
  );

  const chartData = useMemo(() => {
    return getChartData(
      relativeTimezoneOffset,
      timezone,
      timezoneOffset,
      targetRange,
      yMin,
      yMax,
      autoMaxY,
      rightYMin,
      rightYMax,
      bounds,
      readings,
      showTSensChart
    );
  }, [
    readings,
    relativeTimezoneOffset,
    yMin,
    yMax,
    autoMaxY,
    bounds,
    rightYMin,
    rightYMax,
    targetRange,
    timezoneOffset,
    showTSensChart,
  ]);

  const range = useMemo(() => {
    let lowestDate = startDate;
    let highestDate = endDate;
    let highestReading = 0;

    chartData.forEach((l) => {
      l.data.forEach((d) => {
        const x = d.actualDate as Date;

        if (x < lowestDate) {
          lowestDate = x;
        }

        if (x > highestDate) {
          highestDate = x;
        }
      });
    });

    return { lowestDate, highestDate, highestReading };
  }, [chartData, startDate, endDate]);

  return (
    <div
      style={{
        width: '100%',
        height: '520px',
        borderRight: '1px solid #aeaeb7',
      }}
      onClick={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        onClick(
          calculateClickedPoint(
            {
              x: e.clientX - rect.left,
              y: e.clientY - rect.top,
            },
            rect,
            { min: range.lowestDate, max: range.highestDate },
            timezone ?? moment.tz.guess(),
            chartData
          )
        );
      }}
    >
      <Chart
        lines={chartData.concat({
          id: null as any,
          color: 'white',
          data: [
            {
              x: moment(
                getTimezoneAdjustedTime(startDate, -timezoneOffset)
              ).toDate(),
              y: null,
            },
            {
              x: moment(
                getTimezoneAdjustedTime(endDate, -timezoneOffset)
              ).toDate(),
              y: null,
            },
          ],
          dateOffset: 0,
          isPrimarySensor: false,
        })}
        sensor={targetRange.sensor}
        yLabel={yLabel}
        xLabel="Primary Date Range"
        yUnits={yUnit}
        point={false}
        leftYMin={yMin ?? 0}
        leftYMax={yMax ?? autoMaxY}
        rightYMin={rightYMin ?? 0}
        rightYMax={rightYMax ?? bounds.maxRightAxis}
        earliestDate={range.lowestDate}
        latestDate={range.highestDate}
        timezone={timezone ?? moment.tz.guess()}
        bands={bands}
        showEventLogs={showEventLogs}
        showTSensChart={showTSensChart}
      />
    </div>
  );
};
