import { CheckOutlined } from '@ant-design/icons';
import { Box, Button, ExperimentalForm } from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import React from 'react';
import { useState } from 'react';
import { SensorFormFields } from 'Sensors/forms/SensorFormFields';
import { SentryApiClient } from '_generated/api';

export interface SensorCreateProps {
  siteId: number;
  onCreate?: (sensor: SentryApiClient.SensorDTO) => void;
  onError?: (error: Error) => void;
}

export const SensorCreate: React.FC<SensorCreateProps> = ({
  siteId,
  onCreate,
  onError,
}) => {
  const { sensorApi } = useApi();
  const [isCreating, setCreating] = useState(false);

  const [form] = ExperimentalForm.useForm<SentryApiClient.SensorDTO>();

  const onFinish = async (sensor: SentryApiClient.SensorDTO) => {
    setCreating(true);
    let resultingSensor;

    try {
      resultingSensor = await sensorApi.post(sensor);
    } catch (error) {
      onError && onError(error as Error);
    }

    setCreating(false);
    onCreate && resultingSensor && onCreate(resultingSensor);
    form.resetFields();
  };

  return (
    <ExperimentalForm
      wrapperCol={{
        xs: { span: 24 },
      }}
      form={form}
      name="config"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{ siteId }}
    >
      <SensorFormFields visible active form={form} />
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={form.submit}
        loading={isCreating}
      >
        Submit
      </Button>
    </ExperimentalForm>
  );
};
