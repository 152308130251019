import { CheckOutlined } from '@ant-design/icons';
import {
  Box,
  Button,
  ExperimentalForm,
  Stack,
  Switch,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import React from 'react';
import { useState } from 'react';
import { SensorFormFields } from 'Sensors/forms/SensorFormFields';
import { SentryApiClient } from '_generated/api';

export interface SensorEditProps {
  sensor: SentryApiClient.SensorDTO;
  onEdit?: () => void;
  onError?: (error: Error) => void;
}

export const SensorEdit: React.FC<SensorEditProps> = ({
  sensor,
  onEdit,
  onError,
}) => {
  const { sensorApi } = useApi();
  const [isEditing, setCreating] = useState(false);

  const [form] = ExperimentalForm.useForm<SentryApiClient.SensorDTO>();

  const onFinish = async (updatedSensor: SentryApiClient.SensorDTO) => {
    setCreating(true);

    try {
      await sensorApi.put(sensor.id!, updatedSensor);
    } catch (error) {
      onError && onError(error as Error);
    }

    setCreating(false);
    onEdit && onEdit();
  };

  return (
    <ExperimentalForm
      wrapperCol={{
        xs: { span: 24 },
      }}
      form={form}
      name="config"
      onFinish={onFinish}
      initialValues={sensor}
      scrollToFirstError
    >
      <Box padding="xs"></Box>
      <SensorFormFields visible active form={form} />
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={form.submit}
        loading={isEditing}
      >
        Submit
      </Button>
    </ExperimentalForm>
  );
};
