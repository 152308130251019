import { LineOutlined, LoadingOutlined, SignalFilled } from '@ant-design/icons';
import { Box, Stack, Switch, Tooltip } from '@torqit/torq-tools-react';
import { DateServiceSingleton } from 'App/utils/DateService';
import { getNiceColor } from 'App/utils/niceColors';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { PulseLoader } from 'react-spinners';
import {
  SensorReadingLoadState,
  SensorReadingsRangeData,
} from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { SentryApiClient } from '_generated/api';
import styles from './ChartLegend.module.css';
import { LegendItemStats } from './LegendItemStats';
import { useBreakpoints } from 'App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';

interface ChartLegendProps {
  sensor: SentryApiClient.SensorDTO;
  ranges?: SensorReadingsRangeData[];
  timezone?: string;
  onFlipAxis?: (rangeId: string) => void;
  showDetails?: boolean;
}

export const ChartLegend: React.FC<ChartLegendProps> = ({
  sensor,
  ranges = [],
  timezone,
  onFlipAxis,
  showDetails = true,
}) => {
  const getIcon = (loadState: SensorReadingLoadState, color: string) => {
    switch (loadState) {
      case SensorReadingLoadState.NotStarted:
      case SensorReadingLoadState.Loading:
        return <LoadingOutlined style={{ fontSize: 20, color }} />;
      case SensorReadingLoadState.Empty:
        return <LineOutlined style={{ fontSize: 20, color }} />;
      case SensorReadingLoadState.Rendering:
        return <PulseLoader size={5} margin={1} color={color} />;
      case SensorReadingLoadState.Done:
      case SensorReadingLoadState.Stopped:
        return sensor.tSenSActive ? (
          <FontAwesomeIcon
            icon={faTemperatureHigh}
            style={{ fontSize: 20, color: color }}
          />
        ) : (
          <SignalFilled style={{ fontSize: 20, color }} />
        );
    }
  };

  const displayUtcToTimezone = (date?: Date) => {
    if (!date || !timezone) {
      return '';
    }

    if (process.env.NODE_ENV === 'development') {
      return moment(
        DateServiceSingleton.__APPLY_TIMEZONE_FROM_WRONGLY_TIMEZONED_UTC_TIME(
          date,
          timezone
        )
      ).format('lll');
    } else {
      return moment(
        DateServiceSingleton.ConvertToTimezoneTime(date, timezone)
      ).format('lll');
    }
  };

  const { lg } = useBreakpoints();

  return (
    <div className={styles.legend}>
      <Stack direction={showDetails ? 'vertical' : 'horizontal'} gap="sm" fill>
        {ranges.map((r, index) => (
          <Stack direction="vertical" stretch key={r.id}>
            <Stack
              alignment={lg ? 'middle' : 'start'}
              direction={lg ? undefined : 'vertical'}
              style={{ overflowY: 'hidden' }}
            >
              <Box padding={{ right: 'xs' }} width={30} height={20}>
                {getIcon(r.loadState, getNiceColor(index))}
              </Box>
              <div
                className={styles.sensor}
                style={{ color: getNiceColor(index) }}
              >
                {r.sensor.sensorName}
              </div>
              {showDetails && (
                <div>
                  <Box padding={{ left: 'xs' }}>
                    {displayUtcToTimezone(r.startDate)} -{' '}
                    {displayUtcToTimezone(r.endDate)}
                  </Box>
                </div>
              )}
            </Stack>
            {showDetails && (
              <Stack>
                <LegendItemStats readings={r} timezone={timezone} />

                {r.modulo && r.data?.length !== 0 && ranges.length > 1 && (
                  <Stack.Item>
                    <Stack>
                      <Stack alignment="middle" gap="xs">
                        Axis:
                        <Switch
                          className={styles.switch}
                          checked={r.isRightAxis}
                          unCheckedChildren="left"
                          checkedChildren="right"
                          onChange={() => onFlipAxis && onFlipAxis(r.id)}
                        ></Switch>
                      </Stack>
                    </Stack>
                  </Stack.Item>
                )}
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    </div>
  );
};
