import { SensorRange } from 'SensorReadings/interfaces';
import { SentryApiClient } from '_generated/api';
import {
  CalculatorPipe,
  DateTransformPipe,
  DetectOutlierPipe,
  ModuloPipe,
  NormalizePipe,
  OffsetDatePipe,
  Pipeline,
  RoundDatePipe,
} from '../../utils/Pipeline';
import { SharpenRenderer } from './SharpenRenderer';
import {
  SensorReadingLoadState,
  SensorReadingsRangeData,
} from './useSensorReadings';

type OnChangeListener = () => void;

const PAGE_SIZE = 1000;
const IDEAL_RENDER_COUNT = 500;

//The SensorReadingsRangeLoader is responsible for loading all sensor readings
// for a given sensor between two dates. It does this in two separate steps:
// 1) loadFirstPage() -  Load the first page of sensor readings, initializing
//   the pipeline and establishing how many readings need to come down
// 2) waterfallLoadAll() - Load all subsequent pages sequentially, such that
//   as soon as one page is finished loading, the next one is fetched. This
//   way, all data comes down sequentially without hammering the server with
//   api calls all at once.
//
// These loaders are only used ONCE per sensor per range. If new ranges are
// selected, this loader is completely discarded and new loaders are
// initialized with the new ranges, thus starting the process over again.
// When discarded, the loader is stopped, halting the waterfall process
// and blocking any readings from exiting the pipeline
export class SensorReadingsRangeLoader {
  private readingsApi: SentryApiClient.SensorReadingsClient;
  private sensorsApi: SentryApiClient.SensorsClient;

  private range: SensorRange;
  private readings: SensorReadingsRangeData;

  private onChange?: OnChangeListener;

  private pipeline?: Pipeline;
  private renderer?: SharpenRenderer;

  //As elaborated above, the loader is really only meant to be used
  // once per range, but given the nature of react state updates, the
  // waterfall method sometimes ends up getting called multiple times
  // (ex: hot reloading), so instead, we just keep track if waterfalling
  // has been started, and if it has, we ignore all subsequent calls.
  private hasWaterfalled = false;

  public constructor(
    range: SensorRange,
    primaryStartDate: Date,
    readingsApi: SentryApiClient.SensorReadingsClient,
    sensorsApi: SentryApiClient.SensorsClient
  ) {
    this.range = range;
    this.readingsApi = readingsApi;
    this.sensorsApi = sensorsApi;

    const dateOffset = primaryStartDate.getTime() - range.from.getTime();

    this.readings = {
      id: this.range.sensor.id?.toString() + '' + this.range.from.getTime(),
      sensor: this.range.sensor,
      grouping: this.getGrouping(),
      startDate: this.range.from,
      endDate: this.range.to,
      data: [],
      offset: dateOffset,
      loadState: SensorReadingLoadState.NotStarted,
      isRightAxis: false,
    };
  }

  //Keeping the console logs there but commented since they might come in
  // handy at some point.
  private setLoadState(state: SensorReadingLoadState) {
    //const oldState = this.readings.loadState;
    //console.log(oldState, '->', state);
    this.readings.loadState = state;
  }

  //Typescript gets REALLY confused when you access this field directly due to the
  // asynchronous nature of this whole class. Consider the following case:
  /*
    loadState = Loading
    await doAsyncStuff();
    if(loadState == Finished)
  */
  //Here, typescript throws an error on the if statement thinking that loadstate
  // could only possibly be Loading and nothing else, even though stuff may have
  // happened asynchronously. By wrapping these values, typescript doesn't
  // suspect a thing.
  private getLoadState() {
    return this.readings.loadState;
  }

  public setOnChange(listener: OnChangeListener) {
    this.onChange = listener;
  }

  public removeOnChange() {
    this.onChange = undefined;
  }

  public flipYAxis() {
    this.readings.isRightAxis = !this.readings.isRightAxis;
    this.onChange && this.onChange();
  }

  public getRange() {
    return this.range;
  }

  public getReadings() {
    return this.readings;
  }

  public equals(other: SensorRange) {
    return (
      this.range.sensor.id === other.sensor.id &&
      this.range.from.getDate() === other.from.getDate() &&
      this.range.to.getDate() === other.to.getDate()
    );
  }

  public async loadFirstPage() {
    if (this.getLoadState() !== SensorReadingLoadState.NotStarted) {
      return;
    }

    this.setLoadState(SensorReadingLoadState.Loading);

    const data = await this.fetch(1, PAGE_SIZE);

    if (this.getLoadState() !== SensorReadingLoadState.Stopped) {
      if (data.items) {
        this.buildPipeline(data.totalItems ?? 0);
        this.pipeline?.enter(data.items);
      }

      if (PAGE_SIZE > (data.totalItems ?? 0)) {
        this.setLoadState(
          (data.totalItems ?? 0) > 0
            ? SensorReadingLoadState.Rendering
            : SensorReadingLoadState.Empty
        );
      }
    }
  }

  public async loadEventLogs() {
    this.readings.events = (
      await this.sensorsApi.getEventLogs(
        this.range.sensor.id!,
        null,
        null,
        undefined,
        undefined,
        [
          {
            field: SentryApiClient.EventLogFilters.Timestamp,
            operator: SentryApiClient.Operator.GreaterThan,
            value: this.range.from.toISOString(),
          },
          {
            field: SentryApiClient.EventLogFilters.Timestamp,
            operator: SentryApiClient.Operator.LesserThan,
            value: this.range.to.toISOString(),
          },
        ]
      )
    ).items;

    this.onChange && this.onChange();
  }

  private buildPipeline(totalItems: number) {
    const grouping = this.getGrouping();
    this.pipeline = new Pipeline();

    this.pipeline.append(new DateTransformPipe());

    //Calculation now needs to happen prior to the modulo unfortunately, because
    // the modulo now lets readings outside the bands pass through for free, but
    // in order to do that, it needs to know the proper calculated value of the
    // reading to compare the bands to.
    if (this.range.sensor.calculation) {
      this.pipeline.append(
        new CalculatorPipe('VALUE ' + this.range.sensor.calculation)
      );
    }

    this.pipeline.append(new DetectOutlierPipe());
    this.pipeline.append(new NormalizePipe(grouping));

    this.readings.modulo = Math.max(
      1,
      Math.floor(totalItems / IDEAL_RENDER_COUNT)
    );

    this.pipeline.append(new ModuloPipe(this.readings.modulo));
    this.pipeline.append(new RoundDatePipe(this.readings.modulo, grouping));

    if (this.readings.offset !== 0) {
      this.pipeline.append(new OffsetDatePipe(this.readings.offset));
    }

    this.renderer = new SharpenRenderer(64, 50);

    this.pipeline.setOnExit((readings) => {
      this.checkForMinAndMax(readings, this.range.sensor);
      this.renderer?.append(readings);
    });

    this.renderer.setOnUpdate((readings) => {
      this.readings.data = readings;

      this.onChange && this.onChange();
    });

    this.renderer.setOnFinish(() => {
      if (this.getLoadState() !== SensorReadingLoadState.Loading) {
        this.setLoadState(SensorReadingLoadState.Done);
      }

      this.onChange && this.onChange();
    });
  }

  public async waterfallLoadAll() {
    if (
      this.getLoadState() !== SensorReadingLoadState.Loading ||
      this.hasWaterfalled
    ) {
      return;
    }

    this.hasWaterfalled = true;

    let hasMore = true;
    let page = 2;

    while (hasMore) {
      const data = await this.fetch(page, PAGE_SIZE);

      //Refer to big comment above
      if (this.getLoadState() !== SensorReadingLoadState.Stopped) {
        if (data.items) {
          this.pipeline?.enter(data.items);
        }

        if (page >= (data.totalPages ?? 0)) {
          hasMore = false;
          this.setLoadState(SensorReadingLoadState.Rendering);
        } else {
          page++;
        }
      } else {
        hasMore = false;
      }
    }
  }

  private checkForMinAndMax(
    readings: SentryApiClient.SensorReadingDTO[],
    sensor: SentryApiClient.SensorDTO
  ) {
    readings.forEach((r) => {
      if (r.rawValue == null) {
        return;
      }
      if (
        this.readings.minTSensReading == null ||
        (r.tSens && r.tSens < this.readings.minTSensReading.tSens!)
      ) {
        this.readings.minTSensReading = r;
      }

      if (
        this.readings.maxTSensReading == null ||
        (r.tSens && r.tSens > this.readings.maxTSensReading.tSens!)
      ) {
        this.readings.maxTSensReading = r;
      }

      if (
        this.readings.minReading == null ||
        r.rawValue < this.readings.minReading.rawValue!
      ) {
        this.readings.minReading = r;
      }

      if (
        this.readings.maxReading == null ||
        r.rawValue > this.readings.maxReading.rawValue!
      ) {
        this.readings.maxReading = r;
      }
    });
  }

  public stop() {
    this.setLoadState(SensorReadingLoadState.Stopped);

    this.pipeline?.stop();
    this.renderer?.stop();
  }

  private fetch(page: number, pageSize: number) {
    return this.readingsApi.get(
      this.range.sensor.id,
      pageSize,
      page,
      SentryApiClient.SensorReadingFilters.ReadingTime,
      SentryApiClient.OrderDirection.Ascending,
      [
        {
          field: SentryApiClient.SensorReadingFilters.ReadingTime,
          operator: SentryApiClient.Operator.GreaterThan,
          value: this.range.from.toISOString(),
        },
        {
          field: SentryApiClient.SensorReadingFilters.ReadingTime,
          operator: SentryApiClient.Operator.LesserThan,
          value: this.range.to.toISOString(),
        },
        {
          field: SentryApiClient.SensorReadingFilters.Unit,
          operator: SentryApiClient.Operator.Equals,
          value: 'CURRENT',
        },
      ],
      this.getGrouping()
    );
  }

  private getGrouping() {
    const span = this.range.to.getTime() - this.range.from.getTime();

    if (span > 1000 * 60 * 60 * 24 * 14) {
      return SentryApiClient.GroupByParam.Hour;
    }

    //With the implementation of rounding, it's sort of tricky to know
    // how exactly to round raw data which could be coming in at any rate
    // at all, so at the request of Sentry, minutely averaging is now always
    // enabled at very minimum. This way, sensors that produce more than one
    // reading per minute will never round overzealously, and sensors that
    // produce the ordinary one reading per minute pretty much won't be
    // effected.
    return SentryApiClient.GroupByParam.Minute;
  }
}
